<template>
    <b-card>
        <h4>
            فیلتر نمودار معاملات
        </h4>
        <b-button-group class="my-50 mr-75 " size="sm">
            <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    class="btn-icon"
                    variant="outline-warning"
                    @click="$emit('perv')"
            >
                <feather-icon icon="ChevronRightIcon"/>
            </b-button>
            <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :variant="duration === 'DAILY'? 'warning':'outline-warning'"
                    @click="$emit('update:duration','DAILY')"
            >
                روزانه
            </b-button>
            <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :variant="duration === 'MONTHLY'?'warning':'outline-warning'"
                    @click="$emit('update:duration','MONTHLY')"
            >
                ماهانه
            </b-button>
            <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :variant="duration === 'YEARLY'?'warning':'outline-warning'"
                    @click="$emit('update:duration','YEARLY')"
            >
                سالانه
            </b-button>
            <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    class="btn-icon"
                    variant="outline-warning"
                    @click="$emit('next')"
            >
                <feather-icon icon="ChevronLeftIcon"/>
            </b-button>
        </b-button-group>
        <!-- group 2 -->
        <b-button-group class="my-50" size="sm">
            <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :variant="!type?'warning':'outline-warning'"
                    @click="$emit('crypto',false)"
            >
                رمزارز
            </b-button>
            <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    :variant="type?'warning':'outline-warning'"
                    @click="$emit('crypto',true)"
            >
                تومان
            </b-button>
        </b-button-group>
        <div class="d-flex justify-content-between">
            <p>حجم روزانه</p>
            <p>{{$toLocal(sum)}} تومان</p>
        </div>
        <div class="d-flex justify-content-between">
            <p>در روز</p>
            <p>{{$G2J(date)}}</p>
<!--            <p v-else-if="false">{{$jmoment('2020-12-22T00:00:00').format('jMMMM jYYYY')}}</p>-->
<!--            <p v-else>{{$jmoment('2020-12-22T00:00:00').format('jYYYY')}}</p>-->
        </div>
        <h4 class="mt-75" v-if="false">
            حجم معاملات
        </h4>
        <div class="my-50" v-if="false">
            <p class="m-0">52 بیتکوین</p>
            <p class="m-0"> 37 بیتکوین برپایه تتر</p>
            <p class="m-0"> 15 بیتکوین برپایه تومان</p>
        </div>
        <div v-if="false">
            <p class="m-0"> 425,000 تومان</p>
            <p class="m-0">767 تتر برپایه تتر</p>
            <p class="m-0"> 15,000 تومان برپایه تومان</p>
        </div>

    </b-card>
</template>

<script>
    import {
        BCard,
        // BCardHeader,
        // BCardTitle,
        // BCardBody,
        // BCardText,
        BButton,
        BButtonGroup,
    } from 'bootstrap-vue'
    // import {$themeColors} from '@themeConfig'
    export default {
        name: "FilterChart",
        components: {
            BCard,
            // BCardHeader,
            // BCardText,
            // BCardTitle,
            // BCardBody,
            BButton,
            BButtonGroup,
        },
        props:['duration','sum','date','type'],
        data() {
            return {
                duration1: 'crypto',
            }
        }
    }
</script>

<style scoped>

</style>
