<template>
    <b-card no-body>
        <VueTradingView style="height: calc(100vh - 200px)"
                        :key="$store.state.appConfig.layout.skin"
                        :options="{
                                autosize:true,
                                symbol: getMarket,
                                timezone: 'Asia/Tehran',
                                theme: $store.state.appConfig.layout.skin==='dark'?'dark':'light',
                                style: '1',
                                locale: 'fa_IR',
                                enable_publishing: false,
                                withdateranges: true,
                                hide_side_toolbar: false,
                                allow_symbol_change: true,
                                }"/>
    </b-card>
</template>

<script>
    import {BCard} from 'bootstrap-vue'
    import VueTradingView from 'vue-trading-view';

export default {
  name: 'TradingView',
  components: {
    BCard,
    VueTradingView,
  },
  computed: {
    getMarket() {
      const id = this.$route.params.id
      console.log('hello', id)
      if (!id) {
        return 'BINANCE:BTCUSDT'
      }
      const pos = id.indexOf('TOMAN')
      if (pos > -1) {
        if (id.indexOf('USDT') === 0) {
          return 'USDT'
        }
        return 'BINANCE:' + id.slice(0, pos) + 'USDT'
      }
      return 'BINANCE:' + id + 'USDT'
    }
  }
}
</script>
